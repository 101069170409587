export const RentalMovementsConcepts = Object.freeze({
  "non-payment_insurance": "common.nonPaymentInsurance",
  repairs: "common.repairs",
  provision_invoices: "common.utilityBill",
  integral_management: "common.integralManagement",
  others: "common.others",
  community_expenses: "common.communityExpenses",
  supplies: "common.supplies",
  administration_procedures: "common.administrationProcedures",
  rent_vat: "common.rentVat",
  irpf_withholding: "common.irpfWithholding",
  commercialization: "common.commercialization",
  guaranteed_rent: "common.guaranteedRent",
  ibi: "common.ibi",
  other_supply_with_vat: "common.otherSupplyWithVat",
  grant: "common.grant",
  advance_rent: "common.advanceRent",
  management: "common.management",
});
